const cs = {
  pathName: {
    home: "Domů",
    about: "O nás",
    contact: "Kontakt",
    order: "Objednávka",
    photovoltaics: "Fotovoltaika",
    realization: "Realizace",
  },
  welcomePage: {
    offer: "Nabízíme",
    complex: "KOMPLEXNÍ",
    solution: "ŘEŠENÍ",
    photovoltaics: "FOTOVOLTAICKÉ",
    powerStation: "ELEKTRÁRNY",
    introduction:
      "Využijte potenciálu fotovoltaických elektráren na maximum " +
      "a investujte do své budoucnosti." +
      " Zvolte spolehlivost a kvalitu. ",
    button1: "Chci vědět víc",
    button2: "O naší firmě",
    inosys: "inoSys",
    text1:
      "Fotovoltaické elektrárny produkují elektrickou energii ze slunečního záření pomocí fotoelektrického jevu. " +
      "Energii vyrábějí po celý rok a umožňují její skladování. " +
      "Solární energie je nejlevnějším zdrojem elektřiny a představuje podstatně menší ekologickou zátěž, " +
      "než ostatní způsoby výroby elektřiny. " +
      "Díky vlastnostem moderních fotovoltaických článků, naší firmě a možnosti využití státních dotací je " +
      "možné efektivně přeměnit solární záření na elektřinu i u Vás doma.",
    text2:
      "Jsme česká rodinná firma s dlouholetou praxí, která je připravena " +
      "vyhovět Vašim specifickým požadavkům. Na míru zrealizujeme fotovoltaickou " +
      "elektárnu ve Vaší domácnosti či firmě.",
    savings: "Úspora",
    savingsText:
      "Investice do fotovoltaické elektrárny s návratností do 10 let " +
      "Vám umožní dlouhodobě snížit finanční náklady za elektřinu a zvýšit hodnotu Vaší nemovitosti.",
    sustainability: "Udržitelnost",
    sustainabilityText:
      "Fotovoltaické panely jsou recyklovatelné a jejich využití snižuje uhlíkovou stopu. " +
      "Myslete na přírodu a zvolte ekologickou variantu s dlouhou životností.",
    selfSufficency: "Soběstačnost",
    selfSufficencyText:
      "Snižte závislost na hlavní energetické síti a dejte sbohem výpadkům proudu." +
      " Využijte dostupné dotace naplno.",
  },
  pvPage: {
    whyPV: {
      label: "Proč ji pořizovat?",
      bullet1:
        "Zvyšte svoji nezávislost na distribuční síti a prodejte přebytky Vámi vyrobené energie",
      bullet2:
        "Pomožte životnímu prostředí odlehčením páteřních přenosových cest, díky spotřebě energie v místě výroby",
      bullet3:
        "V době nedostatku energie z panelů je výkon doplněn z baterie, která skladuje přebytky pro pozdější spotřebu",
      bullet4:
        "Vyhněte se problémům souvisejícím s výpadky energie, které jsou v odlehlejších oblastech poměrně časté",
    },
    realization: {
      bullet1: "V našich instalacích dominují hybridní měniče Solax a Deye",
      bullet2:
        "Používáme LiFePo bateriová úložiště vysokonapěťová i nízkonapěťová, podle preferencí zákazníka",
      bullet3:
        "Standardní instalace panelů na střešní konstrukce, v případě potřeby je možné realizovat konstrukci na zakázku",
    },
    whyUs: {
      label: "Proč právě my?",
      bullet1: "Konzulatce nabízíme zdarma a dotace vyřídíme za Vás",
      bullet2:
        "Navrhneme, vyměříme a propočítáme nejefektivnější rozložení panelů",
      bullet3: "Materiál pořizujeme od ověřených dodavatelů",
      bullet4: "Montáž a servis provádíme po celé České republice",
      bullet5: "Poskytujeme rychlou a kvalitní instalaci",
      bullet6: "Kompletně navrhneme projekt pro rodinné domy i firmy",
      bullet7:
        "Dotační programy Nová zelená úsporám, resp. Nová zelená úsporám light",
    },

    label: "Nabídka na míru",
    text:
      "Každá instalace je něčím unikátní a proto negeneralizujeme nabídku do kategorií " +
      "na základě nevypovídajících parametrů. Zakázky posuzujeme jednotlivě podle zkušeností nabraných v praxi " +
      "a přizpůsobujeme podle okolností. U nás si tedy nemusíte lámat hlavu výběrem varianty a vše Vám bude přizpůsobeno. " +
      "Nejste-li si jisti využitelností elektrárny na Vašem místě, neváhejte se nás obrátit. " +
      "Naši zaměstnanci " +
      "s Vámi projdou všechny možnosti od A do Z a zodpoví Vaše dotazy. " +
      "Nechte si od nás nezávazně vytvořit návrh" +
      " a investici projít hlavou. Záležitosti spojené " +
      "s dotací vyřídíme za Vás a Vy se nemusíte o nic starat." +
      " ",
    button: "Napište nám",
  },

  about: {
    text:
      "INOSYS s.r.o. je česká rodinná firma fungující od roku 2011. " +
      "Klademe důraz na kvalitně odvedenou práci a individuální přístup. " +
      "Udržitelnost a šetrnost k přírodě je pro nás důležitá a proto nám" +
      " zprostředkovávání fotovoltaických elektráren dává smysl a dělá radost.",
    label1: "Flexibilita",
    label2: "Spolehlivost",
    label3: "Komunikace",
    label4: "Inovace",
    label5: "Profesionalita",
  },
  footer: {
    sitemap: "Mapa webu",
    contacts: "Kontakty",
  },
  alt: {
    logo: "logo firmy",
    menuButton: "otevřít menu",
  },
  cheese: {
    label: "Název oddílu",
    button: "Tlačítko",
    basic:
      "I love cheese, especially cheese on toast port-salut. " +
      "Say cheese jarlsberg gouda st. agur blue cheese pepper " +
      "jack stinking bishop cheese and biscuits fondue. Cheesy grin smelly cheese.  " +
      "Say cheese jarlsberg gouda st. agur blue cheese pepper jack stinking bishop " +
      "cheese and biscuits fondue Say cheese jarlsberg gouda st. agur blue cheese pepper " +
      "jack stinking bishop cheese and biscuits fondue. Cheesy grin smelly cheese. " +
      "I love cheese, especially cheese on toast port-salut. ",
  },
};

export default cs;
