const en = {
  pathName: {
    home: "Home",
    about: "About us",
    contact: "Contact",
    order: "Order",
    photovoltaics: "Photovoltaics",
  },
  footer: {
    sitemap: "Site map",
    contacts: "Contacts",
  },
  cheese: {
    label: "Panel label",
    button: "Button",
    basic:
      "I love cheese, especially cheese on toast port-salut. " +
      "Say cheese jarlsberg gouda st. agur blue cheese pepper " +
      "jack stinking bishop cheese and biscuits fondue. Cheesy grin smelly cheese.  " +
      "Say cheese jarlsberg gouda st. agur blue cheese pepper jack stinking bishop " +
      "cheese and biscuits fondue Say cheese jarlsberg gouda st. agur blue cheese pepper " +
      "jack stinking bishop cheese and biscuits fondue. Cheesy grin smelly cheese. " +
      "I love cheese, especially cheese on toast port-salut. ",
  },
};

export default en;
