import { createI18n } from "vue-i18n";
import cs from "@/i18n/locales/cs";
import en from "@/i18n/locales/en";

const messages = {
  en: en,
  cs: cs,
};

const i18n = createI18n({
  locale: "cs", // set locale TODO ziskavani locale
  fallbackLocale: "cs", // set fallback locale
  messages, // set locale messages
});

export default i18n;
