// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { ThemeDefinition, createVuetify } from "vuetify/dist/vuetify";

const light: ThemeDefinition = {
  dark: false,
  colors: {
    background: "#ffffff",
    surface: "#424242",
    primary: "#546e7a",
    "primary-darken-1": "#37474F",
    secondary: "#eceff1",
    error: "#EF5350",
    info: "#0288D1",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

// TODO upravit barvy
const dark: ThemeDefinition = {
  dark: true,
  colors: {
    background: "#424242",
    surface: "#eceff1",
    primary: "#29434e",
    secondary: "#102027",
    error: "#EF5350",
    info: "#0288D1",
    success: "#4b830d",
    warning: "#FB8C00",
  },
};

export default createVuetify({
  defaults: {},
  theme: {
    defaultTheme: "light", // TODO nastavit ziskavani pomoci localStorage
    themes: {
      light,
      dark,
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
