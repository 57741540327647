import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { nextTick } from "vue";

declare module "vue-router" {
  interface RouteMeta {
    isInMenu: boolean;
    title?: string;
  }
}

const DEFAULT_TITLE = "inoSys"; // TODO install vue-meta and move title definition into specific views to enable translation

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      isInMenu: true,
    },
  },

  {
    path: "/photovoltaics",
    name: "photovoltaics",
    component: () =>
      import(
        /* webpackChunkName: "photovoltaics" */ "../views/PhotovoltaicsView.vue"
      ),
    meta: {
      isInMenu: true,
      title: "Fotovoltaika",
    },
  },
  /*  {
    path: "/order",   TODO pridat az casem
    name: "order",
    component: () =>
      import(/!* webpackChunkName: "order" *!/ "../views/OrderView.vue"),
    meta: {
      inMenu: true,
    },
  },*/
  {
    path: "/realization",
    name: "realization",
    component: () =>
      import(/* webpackChunkName: "realization" */ "../views/Realization.vue"),
    meta: {
      isInMenu: true,
      title: "Realizace",
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
    meta: {
      isInMenu: true,
      title: "Kontakty",
    },
  },

  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      isInMenu: true,
      title: "O nás",
    },
  },

  {
    path: "/404",
    name: "page not found",
    component: () =>
      import(
        /* webpackChunkName: "pagenotfound" */ "../views/PageNotFoundView.vue"
      ),
    meta: {
      isInMenu: false,
      title: "Stránka nenalezena",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
    meta: {
      isInMenu: false,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach((to) => {
  nextTick(() => {
    document.title =
      (to.meta.title ? to.meta.title + " - " : "") + DEFAULT_TITLE;
  });
  window.scrollTo({ top: 0, behavior: "smooth" });
});

export default router;
